import * as React from "react";
import { Link } from "react-router-dom";

export class GetLink extends React.Component<any, any> {

  state = {
    url: "",
    width: undefined,
    height: undefined,
    link: "",
    error: null
  }

  updateUrl(value) {
    this.setState({ url: value })
  }

  updateWidth(value) {
    // check if it's a number
    if (!isNaN(value)) {
      this.setState({ width: value })
    }
  }

  updateHeight(value) {
    // check if it's a number
    if (!isNaN(value)) {
      this.setState({ height: value })
    }
  }

  getLink() {
    const { url, width, height } = this.state;
    // Check if URL is valid
    const validUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(url);
    const validExtension = url.slice(url.length - 4, url.length) === ".swf";

    if (!validUrl) {
      this.setState({ error: "Invalid URL - Please make sure the URL starts with HTTP or HTTPS" });
      return;
    }
    if (isNaN(width)) {
      this.setState({ error: "Please set a width" });
      return;
    }
    if (isNaN(height)) {
      this.setState({ error: "Please set a height" });
      return;
    }
    if (!validExtension) {
      this.setState({ error: "Invalid file extension. Files must be .swf (Flash file)" });
      return;
    }

    else {
      this.setState({ link: `${process.env.REACT_APP_BASE_URL}/link/?url=${url}&width=${width}&height=${height}`, error: null });
    }

  }

  render() {
    const { link, error } = this.state;
    return (
      <div>
        <Link to="/">
          <i className="fa fa-arrow-left" /> Back
        </Link>
        <p>Paste the link to the Flash file you want to play:</p>
        <input value={this.state.url} onChange={(e) => this.updateUrl(e.target.value)} />
        <br />
        <p>Set player dimensions:</p>
        <div className="dimensions">
          Width: <input value={this.state.width} onChange={(e) => this.updateWidth(e.target.value)} />
          Height: <input value={this.state.height} onChange={(e) => this.updateHeight(e.target.value)} />
        </div>
        <button onClick={() => this.getLink()}>Submit</button>
        {link && !error ?
          <div>
            <p>To view your Flash file, copy the link below and paste it in your browser</p>
            <textarea value={link} onChange={(e) => e.preventDefault()} />
          </div> : null}
        {error ? <div className="error">{error}</div> : null}
      </div>
    );
  }
}

export default GetLink;
