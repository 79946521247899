import * as React from "react";
import { BrowserRouter as Router, Route, HashRouter } from "react-router-dom";

import GetLink from "./components/getLink";
import Home from "./components/home";

export class App extends React.Component<any, any> {

  render() {
    return (
      <HashRouter>
        <div id="content">
          <h1>Flash Player Emulator</h1>
          <p>Can't view a Flash module? Upload it here!</p>
          <Route exact path={"/"} component={Home}/>
          <Route path={`/link`} component={GetLink}/>
        </div>
      </HashRouter>
      
    );
  }
}

export default App;
