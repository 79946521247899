import * as React from "react";
import GetLink from "./getLink";

class Home extends React.Component<any, any> {

  render() {
    return (
      <div>
        <GetLink/>
      </div>
    )
  }
}

export default Home;
